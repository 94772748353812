import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';

import Navigation from './shared/components/Navigation/Navigation';
import LoadingSpinner from './shared/components/UIElements/LoadingSpinner';

import './App.css';

const Home = React.lazy( () => import('./quotation/pages/Home'));
const About = React.lazy( () => import('./presentation/pages/About'));
const Cpam = React.lazy( () => import('./presentation/pages/Cpam'));

const App = () => {

  let routes;

  routes = (
    <Switch>
      <Route path="/" exact>
        <Home/>
      </Route>
      <Route path="/about" exact>
        <About/>
      </Route>
      <Route path="/cpam" exact>
        <Cpam/>
      </Route>
      <Redirect to='/'/>
    </Switch>
  );

  return (
    <Router>
      <Navigation className="navigation" />
      <main>
        <Suspense fallback={<div className="center"><LoadingSpinner/></div>}>{routes}</Suspense>
      </main>
    </Router>
  );
};

export default App ;