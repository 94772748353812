import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './NavLinks.css';

const NavLinks = props => {

  const { t, i18n } = useTranslation();

  const changeLanguageHandler = (lang) => {
    if (language === "en") {
        i18n.changeLanguage("fr");
        setLanguage("fr");
    } else {
        i18n.changeLanguage("en");
        setLanguage("en");
    }
  };

  const [language, setLanguage] = useState("fr");
  
  return (
    <ul className={`navigation__links__${props.type}`}>
        <li><a id="phoneNumber" href="tel:0033665688564">{t('homepage.navbar.phone')}</a></li>
        <li><NavLink to="/about">{t('homepage.navbar.about')}</NavLink></li>
        <li><NavLink to="/cpam">{t('homepage.navbar.cpam')}</NavLink></li>
        <li id='language' onClick={changeLanguageHandler}>{t('homepage.navbar.language')}</li>
    </ul>
  );
};

export default NavLinks;
