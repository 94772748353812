import React, { useState } from 'react';

import Drawer from './Drawer';
import Backdrop from '../UIElements/Backdrop';
import Header from './Header';
import NavLinks from './NavLinks';
import DrawerToogle from './DrawerToogle';
import { NavLink } from 'react-router-dom';

import './Navigation.css';
import logo from '../../../images/logo.png';

const Navigation = props => {

    const [drawerIsOpen, setDrawerIsOpen] = useState(false);

    const closeDrawerHandler = () => {
      setDrawerIsOpen(false);
    };

    const drawerToggleClicked = () => {
      setDrawerIsOpen(!drawerIsOpen)
    };

    return (
        <React.Fragment>
            {drawerIsOpen && <Backdrop onClick={closeDrawerHandler} />}
            <Drawer show={drawerIsOpen} type="navigation-drawer">
              <NavLinks type="drawer"/>
            </Drawer>
            <Header>
              <NavLink to="/"><img id="logo" src={logo} alt="Alpes Transport Tourisme"/></NavLink>
              <DrawerToogle clicked={drawerToggleClicked} />
              <NavLinks type="top"/>
            </Header>
        </React.Fragment>
    );

};

export default Navigation;